import { Icons } from "@stacc/flow-ui-components";
import { getRolesFromSessionStorage } from "../utils/userHelper";
import { createMenuDefinitionsSpain } from "./queue-definitions-spain";
import { createMenuDefinitionsSweden } from "./queue-definitions-sweden";
import { createMenuDefinitionsCleanup } from "./cleanup-queue";
import type { MenuStructureItem, Queue } from "./types";


const userRoles = getRolesFromSessionStorage();

let menuStructureSpain: MenuStructureItem[] = [
  {
    name: "Spain",
    elems: [], // Header for Spain
    isOpen: false,
  },
  {
    name: "All applications ", // Keep whitespace to deal with unnique key in react
    icon: Icons.Withdrawal,
    elems: [],
  },
  {
    name: "Caseworker tasks ", // Keep whitespace to deal with unnique key in react
    icon: Icons.ManualWork,
    elems: [],
  },
  {
    name: "Customer tasks ", // Keep whitespace to deal with unnique key in react
    icon: Icons.Smiley,
    elems: [],
  },
  {
    name: "Incidents ", // Keep whitespace to deal with unnique key in react
    icon: Icons.Alert,
    elems: [],
  },
]

let menuStructureSweden: MenuStructureItem[] = [
  {
    name: "Sweden",
    elems: [], // Header for Sweden
    isOpen: false,
  },
  {
    name: "All applications",
    icon: Icons.Withdrawal,
    elems: [],
  },
  {
    name: "Caseworker tasks",
    icon: Icons.ManualWork,
    elems: [],
  },
  {
    name: "Customer tasks",
    icon: Icons.Smiley,
    elems: [],
  },
  {
    name: "Incidents",
    icon: Icons.Alert,
    elems: [],
  }
]

let cleanupStructure: MenuStructureItem[] = [
  {
    name: "",// Header for Cleanup
    elems: [], 
  },
  {
    name: "Cleanup",
    icon: () => <Icons.Trashcan color="#B8B8B8" />,
    elems: [],
  },
];

function createGrouping(queues: Queue[], menuStructure: MenuStructureItem[]): MenuStructureItem[] {
  if (queues.length === 0) {
    return [];
  }

  for (let i = 0; i < menuStructure.length; i++) {
    if (i === 0) {
      continue;
    }

    for (let j = 0; j < queues.length; j++) {
      if (queues[j].groupName.trim() === menuStructure[i].name.trim()) {
        menuStructure[i].elems.push(queues[j].name);
      }
    }
  }
  return menuStructure;
}

// Add queues to menu structure for Spain
const queuesSpain = createMenuDefinitionsSpain(userRoles);

// Add queues to menu structure for Sweden
const queuesSweden = createMenuDefinitionsSweden(userRoles);

// Add queues to cleanup structure
const queuesCleanup = createMenuDefinitionsCleanup(userRoles);

menuStructureSpain = createGrouping(queuesSpain, menuStructureSpain);
menuStructureSweden = createGrouping(queuesSweden, menuStructureSweden);
cleanupStructure = createGrouping(queuesCleanup, cleanupStructure);


export const menu = {
  menuStructure: [...menuStructureSpain, ...menuStructureSweden, ...cleanupStructure],
};
