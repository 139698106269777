import { Select } from "@stacc/flow-ui-components"
import { FormComponentContainer } from "./styles";

type SelectInputPropsCustom = {
  title: string;
  options: { value: boolean | string | number; label: string, disabled?: boolean }[];
  id: string;
  disabled?: boolean;
  onChange: (value: any) => void;
  value: string | number | boolean | null;
  blankOptionLabel?: string;
  showBlankOption?: boolean;
};

export const SelectGroup = (props: SelectInputPropsCustom) => {
  return (
    <FormComponentContainer>
      <div style={{ fontWeight: "600", maxWidth: "300px" }}>{props.title}</div>
      <Select
        options={props.options}
        onChange={(e: any) =>
          props.onChange(e.target.value)
        }
        name={props.id}
        disabled={props?.disabled}
        value={props.value ?? ""}
        blankOptionLabel={props.blankOptionLabel}
        showBlankOption={props.showBlankOption}
      />
    </FormComponentContainer>
  );
};
