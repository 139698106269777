import type { Statistica } from "@flow";
import { NormalInfoWrapper } from "../../../tasks/styles";
import { CopyLink } from "../../copyLink";
import { ModalLink } from "../../modalLink";
import type { CommonData } from "../../../../types/common";

interface OfferDetailsProps {
  data: CommonData
  flowId: string
  handleModalClick: () => void
}

interface Detail {
  label: string,
  value: any,
}


function getIndexOfLastRow(details: Detail[], rejectedPolicies: any) {
  if (rejectedPolicies === null && rejectedPolicies.length <= 0) {
    return []
  }
  let lastValidRow = -1
  for (let i = 0; i < details.length; i++) {
    if (details[i].value != null) {
      lastValidRow = i;
    }
  }
  return [lastValidRow]
}
export const ApplicationtRow = ({ data, flowId, handleModalClick }: OfferDetailsProps) => {
  const SE_Internal = data?.integrations?.statistica?.SE_Internal
  const SE_External = data?.integrations?.statistica?.SE_External
  const showKyc = !!data?.integrations?.cm1

  // Rejection reasons from Statistica
  const statistica: Statistica = data?.integrations?.statistica || {};
  const policiesByStatistica = Object.values(statistica).map(({ policyResults = {} }) => policyResults);
  const rejectedPolicies = policiesByStatistica.flatMap(policyResults =>
    Object.entries(policyResults)
      .filter(([, result]) => result === "Red")
      .map(([policy]) => policy?.trim())
  );
  const rejectReasons = rejectedPolicies.map((rule) => `• ${rule}`,)
  const sortedRejectReasons = rejectReasons.sort((a, b) => a.localeCompare(b));


  const applicationDetails = [
    { label: "FlowId", value: <CopyLink title="Flow ID" value={flowId} /> },
    { label: "Agent ID", value: data?.agentApiApplication?.agentId },
    { label: "Previous applications", value: SE_Internal?.variables?.nbrApplications },
    { label: "Purpose", value: SE_External?.variables?.purpose },
  ];

  if (showKyc) {
    applicationDetails.splice(3, 0, { label: "KYC", value: <ModalLink title="KYC" handleOnClick={handleModalClick} /> })
  }

  const lastIndexes = getIndexOfLastRow(applicationDetails, rejectedPolicies)
  return (
    <>
      <NormalInfoWrapper $header>Application Details</NormalInfoWrapper>
      {applicationDetails.map((detail, index) => {
        if (detail.value === null || detail.value === undefined) return
        return (
          <NormalInfoWrapper key={`application - ${detail.label}`} $isLast={lastIndexes.includes(index) && rejectedPolicies.length<=0}>
            <div>{detail.label}</div>
            <div>{detail.value}</div>
          </NormalInfoWrapper>
        )
      }
      )}
      {
        rejectedPolicies.length > 0 && (
          <>
            <NormalInfoWrapper $isLast>
              Rejection reasons from Statistica
            </NormalInfoWrapper>
            <NormalInfoWrapper $isLast>
              <div>
                {sortedRejectReasons.map((reason, index) => (
                  <div style={{
                    fontSize: "13px",
                  }} key={`reject reason${reason}`}>{reason}</div>
                ))}
              </div>
            </NormalInfoWrapper>
          </>
        )
      }
    </>
  )
}