import styled from 'styled-components';
import MainLogo from './MainLogo';
import { CountrySelection } from './CountrySelection';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  width: 100%;
  color: #4a5568; /* text-gray-800 */
`;

const LeftSection = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

const RightSection = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export function Header() {

  // Only show the country picker when we are on the search app page
  const pathArray = window.location.pathname.split("/")
  const isSearchApp = pathArray.includes("search");

  return (
    <HeaderContainer>
      <LeftSection>
        <MainLogo />
      </LeftSection>
      <RightSection>
        {
          isSearchApp && (
            <CountrySelection />
          )
        }
      </RightSection>
    </HeaderContainer>
  );
}