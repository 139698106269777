import { useState } from 'react';
import styled from 'styled-components';
import { Spain } from '../components/Icons/Flags/Spain';
import { Sweden } from '../components/Icons/Flags/Sweden';

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-right: 0.5rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.4rem 0.8rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  transition: box-shadow 0.2s;
  background-color: #fff;
  border: solid 1px #bbb;
  
  &:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const Dropdown = styled.div`
  position: absolute;
  background-color: #ffffff;
  min-width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
`;

const OptionButton = styled.button<any>`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border:none;
  border-bottom: 1px solid #e2e8f0;
  background-color: ${(props) => (props.selected ? '#ebf8ff' : 'transparent')};
  
  &:hover {
    background-color: #ebf8ff;
  }

`;

const OptionLabel = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const IconWrapper = styled.span`
  width: 1rem;
`;


export function CountrySelection() {
  function setCountry(lang: string) {
    sessionStorage.setItem('country', lang);
    window.location.reload();
  }

  const [isOpen, setIsOpen] = useState(false);

  const selectedCountry = sessionStorage.getItem('country') || 'ES';
  const options = [
    {
      value: 'ES',
      label: 'Spain',
      icon: <Spain />,
      isSelected: selectedCountry === 'ES',
      shouldShow: true,
    },
    {
      value: 'SE',
      label: 'Sweden',
      icon: <Sweden />,
      isSelected: selectedCountry === 'SE',
      shouldShow: true,
    }
  ];

  const languageMapper = (lang: string) => {
    switch (lang) {
      case 'ES':
        return (
          <OptionLabel>
            <IconWrapper><Spain /></IconWrapper>
            <div>
              Spain
            </div>
          </OptionLabel>
        );
      case 'SE':
        return (
          <OptionLabel>
            <IconWrapper><Sweden /></IconWrapper>
            Sweden
          </OptionLabel>
        );
      case 'dev':
        return '🚀 Developer Mode';
      default:
        return null
    }
  };

  return (
    <Container onMouseLeave={() => setIsOpen(false)}>
      <Button onClick={() => setIsOpen(!isOpen)}>
        {languageMapper(selectedCountry)}

      </Button>
      {isOpen && (
        <Dropdown>
          {options.map((option) => {
            if (option.shouldShow)
              return (
                <OptionButton
                  key={option.value}
                  selected={option.isSelected}
                  onClick={() => setCountry(option.value)}
                >
                  <OptionLabel>
                    <IconWrapper>{option.icon}</IconWrapper>
                    {option.label}
                  </OptionLabel>

                </OptionButton>
              );
          })}
        </Dropdown>
      )}
    </Container>
  );
}
