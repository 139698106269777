import { useState } from "react";
import { ApplicantInfoOverview } from "../../../components/Applicant/ApplicantInfoOverview";
import { OverviewHeader } from "../../../components/Header/OverviewHeader";
import type { CommonProcess } from "../../../types/common";
import { ModalTypes, Modals } from "../loan/modals";
import { ApplicantRow } from "../rows/se/applicantRow";
import { ApplicationtRow } from "../rows/se/applicationRow";
import { FinancialRow } from "../rows/se/financialRow";
import { OfferRow } from "../rows/se/offerRow";
import { OverviewWrapper, ProcessWrapper, RowsWrapper } from "../styles";

const CardOverview = (flow: CommonProcess | any) => {
  const { data, flowId } = flow?.caseData ?? {};
  const { country, agentApiApplication: agentApi, } = data || {};
  const { applicant, coapplicant } = agentApi || {};
  const status = flow?.caseData?.status;
  const [selectedModal, setSelectedModal] = useState<string>("");

  function handleModalClose() {
    setSelectedModal("");
  }

  return (
    <OverviewWrapper>
      <OverviewHeader country={country} label="Credit card application" />
      <ProcessWrapper>
        <ApplicantInfoOverview applicant={applicant} coapplicant={coapplicant} />
        <RowsWrapper className="general information">
          <div className="left-side" style={{ marginRight: "10px" }}>
            <ApplicantRow data={data} />
          </div>
          <div className="right-side" style={{ marginLeft: "10px" }}>
            <ApplicationtRow data={data} flowId={flowId} handleModalClick={() => setSelectedModal(ModalTypes.KYC)} />
          </div>
        </RowsWrapper>
        <FinancialRow data={data} isLoan={false} />
        <OfferRow data={data} status={status} />
        <Modals flow={flow} modalToShow={selectedModal} handleModalClose={handleModalClose} />
      </ProcessWrapper>
    </OverviewWrapper >

  );
}

export default CardOverview;