import { func, string } from "prop-types";
import { groupNames, type QueueDefinition, type Queue } from "./types";
import { hasAccessRoles } from "../utils/userHelper";

const pollingInterval = 60000;

// Date 14 days ago, used for rejected queue
const today = new Date();
const date14DaysAgo = new Date(
  today.setDate(today.getDate() - 14),
).toISOString();

const allowedRoles = ["admin-se","caseworker-se","reader-se"];

// @overWriteAccessRoles: Array of roles for specific queues
// Check if user has access to menu
function hasAccessToMenu(userRoles: string[], overwriteAccessRoles?: string[]): boolean {
  return hasAccessRoles(userRoles, overwriteAccessRoles ??  allowedRoles);
}

// queue for active applications
function queueActiveLoanProcess(userRoles: string[]): Queue | null {
  const name = "se-active-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Active",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&hasIncidents=false",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.All_APPLICATIONS,
    };
  }
  return null;
}

function queueReadyForDisbursementProcess(userRoles: string[]): Queue | null {
  const name = "se-ready-for-disbursement-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Ready for disbursement",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&data.queues.ready-for-disbursement=true",
      queueSort: "sort=createdAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.All_APPLICATIONS,
    };
  }
  return null;
}

function queueDisbursedProcess(userRoles: string[]): Queue | null {
  const name = "se-disbursed-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Disbursed",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&data.queues.disbursed=true",
      queueSort: "sort=completedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.All_APPLICATIONS,
    };
  }
  return null;
}

function queueRejectedProcess(userRoles: string[]): Queue | null {
  const name = "se-declined-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Rejected (Last 14 days)",
      name: name,
      queueFilter: `?flowDefinitionId=common-process&data.country=SE&view=queue-items&status[]=completed&status[]=archived&data.queues.disbursed=false&updatedAt=>${date14DaysAgo}`,
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.All_APPLICATIONS,
    };    
  }
  return null;
}

// queue case worker tasks

function queueManualReviewProcess(userRoles: string[]): Queue | null {
  const name = "se-manual-casework-review";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Income Review",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&data.queues.manual-payout-approval=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueManualAMLReviewProcess(userRoles: string[]): Queue | null {
  const name = "se-manual-casework-aml";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "AML Review",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&data.queues.manual-pep-review=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueBgCheckProcess(userRoles: string[]): Queue | null {
  const name = "se-manual-bgc-check";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "BGC manual check",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&data.queues.manual-bgc-handling=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueCrossDataCheckProcess(userRoles: string[]): Queue | null {
  const name = "se-cross-data-check";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Cross Data Check",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&data.queues.cross-data-check=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueManualCheckFinalProcess(userRoles: string[]): Queue | null {
  const name = "se-manual-check-final";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Disbursement Check",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&data.queues.manual-check-final=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

// queue for customer tasks

function queueAcceptLoanProcess(userRoles: string[]): Queue | null {
  const name = "se-awaiting-accept-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Accept Loan Offer",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&data.queues.awaiting-accept=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueStartInformationProcess(userRoles: string[]): Queue | null {
  const name = "se-start-information";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Start Information",
      name: name,
      queueFilter: "?flowDefinitionId=loan-se&view=queue-items&status=active&data.queues.start-information=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueTinkConsentProcess(userRoles: string[]): Queue | null {
  const name = "se-tink-consent";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Tink Consent",
      name: name,
      queueFilter: "?flowDefinitionId=loan-se&view=queue-items&status=active&data.queues.tink-consent=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20, 
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueCustomerInfoRefinanceProcess(userRoles: string[]): Queue | null {
  const name = "se-customer-info-refinance";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Customer Info Refinance",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&data.queues.customer-info-refinance=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
} 

function queueInsuranceSaleProcess(userRoles: string[]): Queue | null {
  const name = "se-insurance-sale";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Insurance Sale",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&data.queues.insurance-sale=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  } 
  return null;
}

function queueSignDocumentsProcess(userRoles: string[]): Queue | null {
  const name = "se-awaiting-signing-cases-queue"; 
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Sign Documents",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&data.queues.awaiting-signing=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

// queue for incidents
function queueFailedCasesUpdateAgentProcess(userRoles: string[]): Queue | null {
  const name = "se-failed-cases-update-agent-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Incidents (Update Agent)",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&hasIncidents=true&data.errors.update-agent=",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.INCIDENTS,
    };
  }
  return null;
}

function queueFailedCasesProcess(userRoles: string[]): Queue | null {
  const name = "se-failed-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Incidents",
      name: name,
      queueFilter: "?flowDefinitionId=common-process&data.country=SE&view=queue-items&status=active&hasIncidents=true&data.errors.update-agent=!",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.INCIDENTS,
    };  
  }
  return null;
}

function queues(userRoles: string[]): (Queue | null)[] {
  return [
    queueActiveLoanProcess(userRoles),
    queueReadyForDisbursementProcess(userRoles),
    queueDisbursedProcess(userRoles),
    queueRejectedProcess(userRoles),
    queueManualReviewProcess(userRoles),
    queueManualAMLReviewProcess(userRoles),
    queueBgCheckProcess(userRoles),
    queueCrossDataCheckProcess(userRoles),
    queueManualCheckFinalProcess(userRoles),
    queueAcceptLoanProcess(userRoles),
    queueStartInformationProcess(userRoles),
    queueTinkConsentProcess(userRoles),
    queueCustomerInfoRefinanceProcess(userRoles),
    queueInsuranceSaleProcess(userRoles),
    queueSignDocumentsProcess(userRoles),
    queueFailedCasesUpdateAgentProcess(userRoles),
    queueFailedCasesProcess(userRoles),
  ];
}

export function createQueueDefinitionsSweden(userRoles: string[]): { [key: string]: Queue } {
  const validQueues = queues(userRoles).filter((queue): queue is Queue => queue !== null);
  const queueDefinitions = validQueues.reduce((acc: QueueDefinition, queue) => {
    acc[queue.name] = queue;
    return acc;
  }
  , {});
return queueDefinitions;
}

export function createMenuDefinitionsSweden(userRoles: string[]):  Queue[] {
  const validQueues = queues(userRoles).filter((queue): queue is Queue => queue !== null);
return validQueues;
}
