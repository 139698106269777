import { hasAccessRoles } from "../utils/userHelper";
import { groupNames, type Queue, type QueueDefinition } from "./types";

const pollingInterval = 60000;

// Date 14 days ago, used for rejected queue
const today = new Date();
const date14DaysAgo = new Date(
  today.setDate(today.getDate() - 14),
).toISOString();

const allowedRoles = ["admin-es","caseworker-es","reader-es"];

// @overWriteAccessRoles: Array of roles for specific queues
// Check if user has access to menu

function hasAccessToMenu(userRoles: string[], overwriteAccessRoles?: string[]): boolean {
  return hasAccessRoles(userRoles, overwriteAccessRoles ??  allowedRoles);
}

// queue for active applications
function queueActiveLoanProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-active-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Active",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&hasIncidents=false",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.All_APPLICATIONS,
    };
  }
  return null;
}

function queueReadyForDisbursementProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-ready-for-disbursement-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Ready for disbursement",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.ready-for-disbursement=true",
      queueSort: "sort=createdAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.All_APPLICATIONS,
    };
  }
  return null;
}

function queueDisbursedProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-disbursed-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Disbursed",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&data.queues.disbursed=true",
      queueSort: "sort=completedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.All_APPLICATIONS,
    };
  }
  return null;
}

function queueRejectedProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-declined-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Rejected (Last 14 days)",
      name: name,
      queueFilter: `?flowDefinitionId=loan-es&view=queue-items&status[]=completed&status[]=archived&data.queues.disbursed=false&updatedAt=>${date14DaysAgo}`,
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.All_APPLICATIONS,
    };    
  }
  return null;
}

// queue case worker tasks

function queueManualReviewProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-manual-casework-review";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Income Review",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-payout-approval=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueManualAMLReviewProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-manual-casework-aml";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "AML Review",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-pep-review=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueManualIDReviewProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-manual-id-review";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "ID Review",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-id-review=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueCrossDataCheckProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-cross-data-check";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Cross Data Check",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.cross-data-check=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueManualCheckFinalProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-manual-check-final";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Disbursement Check",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-check-final=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}

function queueManualConsolidationProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-manual-check-debt-consolidation";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Debt Consolidation",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-check-debt-consolidation=true",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CASEWORKER_TASKS,
    };
  }
  return null;
}


// queue for customer tasks

function queueWithPSD2DataProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-with-psd2-data-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "With PSD2 data",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.awaiting-accept=true&data.agentApiApplication.applicant.containsPSD2Data=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}
function queueWithoutPSD2DataProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-without-psd2-data-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Without PSD2 data",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.awaiting-accept=true&data.agentApiApplication.applicant.containsPSD2Data=false",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueInsuranceProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-insurance-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Insurance",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.businessStatus=insurance-sale",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueAwaitingVerificationProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-awaiting-verification-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Verify IBAN",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.businessStatus=confirm-account-number",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueAwaitingConfirmIdentityProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-awaiting-confirm-identity-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Confirm Identity",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.businessStatus=confirm-identity",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueConfirmSefSchemaProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-confirm-sef-schema-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Confirm SEF Schema",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.confirm-sef-schema=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueUploadRefinanceDocumentationProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-upload-refinance-documentation";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Upload refinance documentation",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.businessStatus=upload-refinance-documentation",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

function queueAwaitingSigningProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-awaiting-signing-cases-queue";
  if (hasAccessToMenu(userRoles)) { 
    return {
      label: "Sign Documents",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.awaiting-signing=true",
      queueSort: "sort=updatedAt&dir=-1",
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.CUSTOMER_TASKS,
    };
  }
  return null;
}

// queue for incidents
function queueFailedCasesUpdateAgentProcess(userRoles: string[]): Queue | null {
  const name = "es-failed-cases-update-agent-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Incidents (Update Agent)",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&hasIncidents=true&data.errors.update-agent=",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.INCIDENTS,
    };
  }
  return null;
}

function queueFailedCasesProcess(userRoles: string[]): Queue | null {
  const name = "es-loan-failed-cases-queue";
  if (hasAccessToMenu(userRoles)) {
    return {
      label: "Incidents",
      name: name,
      queueFilter: "?flowDefinitionId=loan-es&view=queue-items&status=active&hasIncidents=true&data.errors.update-agent=!",
      queueSort: "sort=updatedAt&dir=-1",
      pollingEnabled: false,
      limit: 20,
      pollingInterval: pollingInterval,
      groupName: groupNames.INCIDENTS,
    };  
  }
  return null;
}

function queues(userRoles: string[]): (Queue | null)[] {
  return [
    queueActiveLoanProcess(userRoles),
    queueReadyForDisbursementProcess(userRoles),
    queueDisbursedProcess(userRoles),
    queueRejectedProcess(userRoles),
    queueManualReviewProcess(userRoles),
    queueManualAMLReviewProcess(userRoles),
    queueManualIDReviewProcess(userRoles),
    queueCrossDataCheckProcess(userRoles),
    queueManualCheckFinalProcess(userRoles),
    queueManualConsolidationProcess(userRoles),
    queueWithPSD2DataProcess(userRoles),
    queueWithoutPSD2DataProcess(userRoles),
    queueInsuranceProcess(userRoles),
    queueAwaitingVerificationProcess(userRoles),
    queueAwaitingConfirmIdentityProcess(userRoles),
    queueConfirmSefSchemaProcess(userRoles),
    queueUploadRefinanceDocumentationProcess(userRoles),
    queueAwaitingSigningProcess(userRoles),
    queueFailedCasesUpdateAgentProcess(userRoles),
    queueFailedCasesProcess(userRoles),
  ];
}

export function createQueueDefinitionsSpain(userRoles: string[]): { [key: string]: Queue } {
  const validQueues = queues(userRoles).filter((queue): queue is Queue => queue !== null);
  const queueDefinitions = validQueues.reduce((acc: QueueDefinition, queue) => {
    acc[queue.name] = queue;
    return acc;
  }
  , {});
return queueDefinitions;
}

export function createMenuDefinitionsSpain(userRoles: string[]):  Queue[] {
  const validQueues = queues(userRoles).filter((queue): queue is Queue => queue !== null);
return validQueues;
}
