import type { CommonData } from "../../../../types/common";
import { NormalInfoWrapper } from "../../../tasks/styles";
import { RowsWrapper } from "../../styles";

interface OfferDetailsProps {
  data: CommonData
  isLoan?: boolean
}

interface Detail {
  label: string,
  value: any,
}
function getDebtToBeReedemed(data: CommonData) {
  const customerDebts = data?.integrations?.LeaBankWeb?.customerInformation?.debts
  if (!customerDebts) {
    return data?.agentApiApplication?.application?.refinanceAmount
  }
  return `${customerDebts.reduce((total, debt) => total + debt.amount, 0)}`;
}

function getIndexOfLastRow(details: Detail[]) {
  let lastValidRow = -1;
  let secondLastValidRow = -1;
  let validCount = 0;

  for (let i = 0; i < details.length; i++) {
    if (details[i].value != null) {
      secondLastValidRow = lastValidRow;
      lastValidRow = i;
      validCount++;
    }
  }

  return validCount % 2 === 0
    ? [secondLastValidRow, lastValidRow]
    : [lastValidRow];
}

export const FinancialRow = ({ data, isLoan = true }: OfferDetailsProps) => {
  const SE_External = data?.integrations?.statistica?.SE_External
  const totUnsecuredDebtBal = SE_External?.variables?.totUnsecuredDebtBal;
  const debtRatio = SE_External?.variables?.debtRatio;
  const DebtToBeRedeemed = getDebtToBeReedemed(data)
  const ProbabilityOfDefaul = SE_External?.variables?.ProbabilityOfDefault
  const OwnsDwelling = SE_External?.variables?.NDI

  const incomeLastYear = SE_External?.variables?.annualStatedGrossIncome
  const debtLastYear = null

  const financialDetails = [
    { label: "Income last year", value: incomeLastYear },
    { label: "Debt last year", value: debtLastYear },
    { label: "Total debt ratio", value: debtRatio },
    { label: "Unsecured debt ratio", value: totUnsecuredDebtBal },
    { label: "PD", value: ProbabilityOfDefaul },
    { label: "OD", value: OwnsDwelling },
  ];

  if (isLoan) {
    financialDetails.push({ label: "Debt to be redeemed", value: DebtToBeRedeemed })
    financialDetails
  }

  const lastIndexes = getIndexOfLastRow(financialDetails)

  return (
    <RowsWrapper className="Financial information">
      <NormalInfoWrapper $header>Financial Details</NormalInfoWrapper>
      {financialDetails.map((detail, index) => {
        if (detail.value === null || detail.value === undefined) return
        return (
          <NormalInfoWrapper key={`offer - ${detail.label}`} $isLast={lastIndexes.includes(index)}>
            <div>{detail.label}</div>
            <div>{detail.value}</div>
          </NormalInfoWrapper>
        )
      }
      )}
    </RowsWrapper>
  )
}