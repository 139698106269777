import { Icons } from "@stacc/flow-ui-components";
import {
  AttachmentsTab,
  ErrorsAttachmentsTab,
} from "../views/attachments/AttachmentsTab";
import OverviewTab from "../views/overview";
import { QueueItem } from "../components/Queue/QueueItem";
import type { Flow } from "@flow";


import { menu } from "./menu-structure";
import { createQueueDefinitionsSpain } from "./queue-definitions-spain";
import { getRolesFromSessionStorage } from "../utils/userHelper";
import { createQueueDefinitionsSweden } from "./queue-definitions-sweden";
import { createQueueDefinitionsCleanup } from "./cleanup-queue";
import { tasks } from "./tasks";


const roles = getRolesFromSessionStorage();

const CustomComponents = () => {

  return {
    case: {
      businessStatus: {
        map: (flow: Flow) => {
          return flow.data.businessStatus;
        },
      },
      tabs: [
        {
          id: "attachmentsTab",
          title: "Attachments",
          component: AttachmentsTab,
          hide: (flow: Flow) => flow.numAttachments <= 0,
        },
        {
          id: "errorsTab",
          title: "Errors",
          component: ErrorsAttachmentsTab,
          hide: (flow: Flow) =>
            (flow.flowDefinitionId !== "cleanup" &&
              flow.flowDefinitionId !== "manual-cleanup") ||
            flow.numAttachments <= 0,
        },
      ],
      tasks: tasks,
      caseSummary: {
        component: OverviewTab,
      },
    },
    queue: {
      queueItem: QueueItem,
    },
    menu: menu,
    // Menu items that fetch flow-processes.
    queueDefinition: {
      ...createQueueDefinitionsSpain(roles),
      ...createQueueDefinitionsSweden(roles),
      ...createQueueDefinitionsCleanup(roles),
    },
  };
};

export { CustomComponents };
