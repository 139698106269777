import { ApplicantCollection } from "../../../components/Applicant/ApplicantCollection";
import { RadioButtonGroup } from "../../../components/Decision/RadioButtonGroup";
import { FormTextArea } from "../../../components/Input/FormTextArea";
import { InfoRow } from "../../../components/InfoRow/InfoRow";
import {
  LabelBold,
  ApplicantDetailsWrapper,
  ApplicantDetailsLabel,
} from "../manual-check-final/styles";
import { FlexAlignCenter } from "../manual-id-review/style";
import * as Styles from "../styles";
import { Icons } from "@stacc/flow-ui-components";
import type { CommonData } from "../../../types/common";
import { SelectGroup } from "../../../components/Select/SelectGroup";

interface FormConfigProps {
  errorMessages: string | null;
  applicant: any;
  coapplicant: any;
  decision: any;
  insuranceSale: any;
  handleInsuranceSaleChange: (value: any) => void;
  handleDecisionChange: (value: any) => void;
  handleNoteChange: (value: any) => void;
  comment: string;
  data: CommonData;
  status: string;
}

export const formConfig = ({
  errorMessages,
  applicant,
  coapplicant,
  decision,
  insuranceSale,
  handleDecisionChange,
  handleNoteChange,
  handleInsuranceSaleChange,
  comment,
  data,
  status,
}: FormConfigProps) => {
  const {
    finalLoanAmount,
    interestRate,
    effectiveInterestRate,
    maxLoanAmount,
    minLoanAmount,
    startupFee,
    termFee,
    terms,
    monthlyCost,
    refinanceAmount,
    topUpAmount,
    cashPayoutAmount,
    insuranceCost,
    insuranceSelected,
  } = data?.offer || {};

  const offerGivenIcon = finalLoanAmount ? (
    <Icons.SuccessCircle />
  ) : status === "active" ? (
    <Icons.ActiveCircle />
  ) : (
    <Icons.AbortedCircle />
  );

  const applicationDetailsOptions = [
    { label: "Final loan amount", value: finalLoanAmount },
    ...(topUpAmount
      ? [{ label: "Internal loan amount", value: topUpAmount }]
      : []),
    { label: "Cash payout amount", value: cashPayoutAmount },
    { label: "Refinance amount", value: refinanceAmount },
    { label: "Interest rate", value: interestRate },
    { label: "Maximum loan amount", value: maxLoanAmount },
    { label: "Minimum loan amount", value: minLoanAmount },
    { label: "Startup fee", value: startupFee },
    { label: "Term fee", value: termFee },
    { label: "Terms", value: terms },
    { label: "Effective interest rate", value: effectiveInterestRate },
    { label: "Monthly cost", value: monthlyCost },
    { label: "Offer given", value: offerGivenIcon },
    {
      label: "Insurance Cost",
      value: `${new Intl.NumberFormat().format(insuranceCost ?? 0)} kr`,
    },
    { label: "Insurance Choosen", value: insuranceSelected ? "Yes" : "No" },
  ];

  const components = [
    {
      // Applicant details
      component: (
        <>
          <ApplicantDetailsWrapper>
            <ApplicantDetailsLabel>Applicant details</ApplicantDetailsLabel>
            <ApplicantCollection {...applicant} />
            {coapplicant && <ApplicantCollection {...coapplicant} />}
          </ApplicantDetailsWrapper>
        </>
      ),
      order: 1,
    },
    // Address details
    {
      component: (
        <div
          style={{
            padding: "0 28px",
          }}
        >
          <InfoRow
            value={data?.integrations?.uc?.addressInformation?.streetName || ""}
            title="Address"
            hasMatch={false}
            hasPadding={false}
          />
          <InfoRow
            value={
              data?.integrations?.LeaBankWeb?.customerInformation
                ?.accountNumber ??
              data?.agentApiApplication?.application?.bankAccountNumber ??
              ""
            }
            title="Account number"
            hasMatch={false}
            hasPadding={false}
          />
        </div>
      ),
      order: 2,
    },
    // Application details
    {
      component: (
        <>
          <ApplicantDetailsLabel>Application details</ApplicantDetailsLabel>
          <div
            style={{
              padding: "0 28px",
            }}
          >
            {applicationDetailsOptions.map((option, index) => (
              <InfoRow
                value={option.value}
                title={option.label}
                key={`infoRow${option.label}`}
                hasMatch={false}
                hasPadding={false}
              />
            ))}
          </div>
        </>
      ),
      order: 3,
    },
    // Decision
    {
      component: (
        <RadioButtonGroup
          title="Decision"
          id="approved"
          radioButtonValue={decision}
          options={[
            { label: "Approve application", value: true },
            { label: "Reject application", value: false },
          ]}
          onChange={(e: any) => handleDecisionChange(e)}
        />
      ),
      order: 5,
    },
    // Internal comment
    {
      component: (
        <>
          <FormTextArea
            label="Internal comment"
            name="internal-comment"
            onChange={(e: any) => handleNoteChange(e)}
            defaultValue={comment}
            placeholder="Other applicant information..."
          />
          {errorMessages && (
            <Styles.ErrorMessageBox>
              <FlexAlignCenter>
                <LabelBold>{errorMessages}</LabelBold>
              </FlexAlignCenter>
            </Styles.ErrorMessageBox>
          )}
        </>
      ),
      order: 6,
    },
  ];

  if (!insuranceSelected) {
    //Insurance sale if insurance is not selected
    components.push({
      component: (
        <SelectGroup
          title="Insurance sale"
          id="insuranceSale"
          value={insuranceSale}
          options={[
            { label: "Without insurance", value: false },
            { label: "With insurance", value: true },
          ]}
          onChange={(value: any) => handleInsuranceSaleChange(value)}
        />
      ),
      order: 4,
    });
  }

  return components;
};
