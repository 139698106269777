import { JSX } from "react";
import { NormalInfoWrapper } from "../../views/tasks/styles";
import { ProcessIDWrapper } from "./styles";

type IdConfig = {
    title: string;
    content: JSX.Element;
};

export const OverviewIdRowCollection = (props: {
    config: IdConfig[];
}) => {
    const { config } = props;
    return config.map((item, index) => {
        return (
            <NormalInfoWrapper key={index}>
                <div>{item.title}</div>
                <ProcessIDWrapper>{item.content}</ProcessIDWrapper>
            </NormalInfoWrapper>
        );
    });
};
