import { useState } from "react";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import { infoConfig } from "./infoConfig";
import { formConfig } from "./formConfig";
import { validateDecision } from "./validation";
import type { CommonData } from "../../../types/common";

const ManualCheckFinalSe = (props: any) => {
  const data: CommonData = props.flow?.data;
  const applicant = data?.agentApiApplication?.applicant;
  const coapplicant = data?.agentApiApplication?.coapplicant;
  const status = props.flow?.caseData?.status;

  const [checklistValues, setChecklistValues] = useState(
    props.task?.data.checklistValues ?? [],
  );
  const [decision, setDecision] = useState(props.task?.data.decision ?? null);
  const [comment, setComment] = useState(props.task?.data.comment ?? null);
  const [insuranceSale, setInsuranceSale] = useState(
    props.task?.data.insuranceSale ?? null,
  );

  const [errorMessages, setErrorMessages] = useState<string | null>(null);

  const handleDecisionChange = (value: any) => {
    const decisionValue = value === "true";
    setDecision(decisionValue);
  };

  const handleInsuranceSaleChange = (value: any) => {
    const insuranceSaleValue = value === "true";
    setInsuranceSale(insuranceSaleValue);
  };

  const handleNoteChange = (value: any) => {
    setComment(value);
  };

  const handleSave = async () => {
    const formData = {
      checklistValues,
      decision,
      insuranceSale,
      comment,
    };
    props.save(formData, (error: any) => console.error({ error }), true);
  };
  const buttonIsDisabled = () => {
    let errorMessage = null;
    errorMessage = validateDecision(decision);
    if (errorMessage) {
      return true;
    }
    return false;
  };

  const handleComplete = async (): Promise<void> => {
    const checklist = checklistValues.map((value: string) => {
      return {
        text: value,
        checked: true,
      };
    });

    const formData = {
      checklist,
      decision,
      insuranceSale: insuranceSale ?? false,
      comment: comment ?? "",
    };

    props.complete(
      formData,
      () => {},
      (e: any) => {
        setErrorMessages(e?.detail);
      },
    );
  };

  return (
    <CommonUserTaskNew
      handleComplete={handleComplete}
      handleSave={handleSave}
      infoConfig={infoConfig({ checklistValues, setChecklistValues })}
      formConfig={formConfig({
        errorMessages,
        applicant,
        coapplicant,
        decision,
        insuranceSale,
        handleDecisionChange,
        handleNoteChange,
        handleInsuranceSaleChange,
        comment,
        data,
        status,
      })}
      overrideMasonryColumns={1}
      disablePrimary={buttonIsDisabled()}
    />
  );
};

export default ManualCheckFinalSe;
