import ChangeContactInformation from "../views/tasks/change-contact-information";
import CrossDataCheck from "../views/tasks/cross-data-check";
import ManualCheckBgc from "../views/tasks/manual-check-bgc";
import ManualCheckFinal from "../views/tasks/manual-check-final";
import ManualIdReview from "../views/tasks/manual-id-review";
import ManualInsuranceSale from "../views/tasks/manual-insurance-sale";
import ManualApplicationReview from "../views/tasks/manual-payout-approval";
import ManualPepReview from "../views/tasks/manual-pep-review";
import AppWrapper from "../views/tasks/poa-and-refinance-documentation";
import ChangeApplication from "../views/tasks/trigger-change-application";
import ChangeLoanAmount from "../views/tasks/trigger-change-loan-amount";
import ContactApplicant from "../views/tasks/trigger-communication-process";
import UpdateApplication from "../views/tasks/update-application";
import UpdateRefinanceTask from "../views/tasks/update-refinance";

export const tasks = {
  taskComponents: {
    "manual-pep-review": {
      titleMap: () => "Manual AML / PEP Review",
      component: ManualPepReview,
      size: "small",
      customWidth: "90%",
      customHeight: "90%",
    },
    "manual-payout-approval": {
      titleMap: () => "Manual Payout Approval",
      component: ManualApplicationReview,
      size: "small",
      customWidth: "90%",
      customHeight: "90%",
    },
    "manual-id-review": {
      titleMap: () => "Manual ID Review",
      component: ManualIdReview,
      size: "small",
      customWidth: "90%",
      customHeight: "90%",
    },
    "cross-data-check": {
      titleMap: () => "Cross Data Check",
      component: CrossDataCheck,
      customWidth: "max-content",
      customHeight: "max-content",
    },
    "verify-refinance-documentation": {
      titleMap: () => "Verify refinancing details",
      component: AppWrapper,
      customWidth: "max-content",
      customHeight: "max-content",
    },
    "update-refinance": {
      titleMap: (task: any) => "Update refinance",
      component: UpdateRefinanceTask,
      customWidth: "max-content",
      customHeight: "max-content",
    },
    "manual-check-final": {
      titleMap: () => "Final check before disbursement",
      size: "small",
      component: ManualCheckFinal,
      customWidth: "max-content",
      customHeight: "max-content",
    },
    "manual-bgc-handling": {
      titleMap: (task: any) => "Manual Bank Giro Check",
      component: ManualCheckBgc,
      customWidth: "max-content",
      customHeight: "max-content",
    },
    "manual-insurance-sale": {
      titleMap: (task: any) => "Manual Insurance Sale",
      component: ManualInsuranceSale,
      customWidth: "max-content",
      customHeight: "max-content",
    },
    "application-updated": {
      disabled: true,
    },
    "trigger-communication-process": {
      titleMap: () => "Contact applicant",
      component: ContactApplicant,
    },
    "contact-applicant": {
      titleMap: (task: any) => "Contact applicant",
      component: ContactApplicant,
    },
    "trigger-change-application": {
      titleMap: () => "Change application",
      component: ChangeApplication,
    },
    "change-contact-information": {
      titleMap: (task: any) => "Change contact information",
      component: ChangeContactInformation,
      customWidth: "max-content",
      customHeight: "max-content",
    },
    "trigger-change-loan-amount": {
      titleMap: () => "Change loan amount",
      component: ChangeLoanAmount,
    },
    "update-application": {
      titleMap: (task: any) => "Update application",
      component: UpdateApplication,
      customWidth: "max-content",
      customHeight: "max-content",
    },
  },
}