import { FEATURES } from "@flow/case-manager";
import { format } from "date-fns";

const country = sessionStorage.getItem('country') || 'ES';

const searchFieldsSpain = [{ name: "Flow Application ID", value: "flowId" },
{ name: "Agent Used", value: "data.agentApiApplication.agentId" },
{
  name: "External Application ID",
  value: "data.agentApiApplication.externalApplicationId",
},
{
  name: "Status",
  value: "data.lastCallback.endpoint",
  type: "select",
  options: [
    { value: "approved", label: "Approved" },
    { value: "canceled", label: "Canceled" },
    { value: "declined", label: "Declined" },
    { value: "disbursed", label: "Disbursed" },
    { value: "document-signed", label: "Document Signed" },
    {
      value: "ready-for-disbursement",
      label: "Ready For Disbursement",
    },
  ],
},
{
  name: "Country",
  value: "data.country",
  type: "select",
  options: [
    { value: "ES", label: "Spain" },
  ],
},
{
  name: "Applicant's First Name",
  value: "data.agentApiApplication.applicant.firstName",
  queryOperator: "contains",
},
{
  name: "Applicant's Last Name",
  value: "data.agentApiApplication.applicant.lastName",
  queryOperator: "contains",
},
{
  name: "Applicant's National ID",
  value: "data.agentApiApplication.applicant.nationalId",
},
{
  name: "Applicant's Phone Number",
  value: "data.agentApiApplication.applicant.mobileNumber",
},
{
  name: "Applicant's E-mail",
  value: "data.agentApiApplication.applicant.emailAddress",
},
{
  name: "Campaign Id",
  value: "data.agentApiApplication.campaignId",
},
{
  name: "Business Status",
  value: "data.businessStatus",
  type: "select",
  options: [
    { value: "Active", label: "Active" },
    { value: "awaiting-accept", label: "Awaiting-accept" },
    { value: "Transferred to Core", label: "Transferred to Core" },
    { value: "confirm-account-number", label: "Confirm-account-number" },
    { value: "confirm-identity", label: "Confirm-identity" },
    { value: "confirm-sef-schema", label: "Confirm-sef-schema" },
    { value: "cross-data-check", label: "Cross-data-check" },
    { value: "insurance-sale", label: "Insurance-sale" },
    { value: "manual-check-final", label: "Manual-check-final" },
    { value: "manual-id-review", label: "Manual-id-review" },
    { value: "manual-payout-approval", label: "Manual-payout-approval" },
    { value: "manual-pep-review", label: "Manual-pep-review" },
    { value: "sign-documents", label: "Sign-documents" },
    { value: "upload-psd2-data", label: "Upload-psd2-data" },
    { value: "upload-refinance-documentation", label: "Upload-refinance-documentation" },
    { value: "verify-refinance-documentation", label: "Verify-refinance-documentation" },
  ],
},
{
  name: "Created after",
  value: "createdAt",
  type: "date",
  queryOperator: "gte",
  max: format(Date.now(), "yyyy-MM-dd"),
},
{
  name: "Created before",
  value: "createdAt",
  type: "date",
  queryOperator: "lt",
  max: format(Date.now(), "yyyy-MM-dd"),
}]




const searchFieldsSweden = [{ name: "Flow Application ID", value: "flowId" },
{ name: "Agent Used", value: "data.agentApiApplication.agentId" },
{
  name: "External Application ID",
  value: "data.agentApiApplication.externalApplicationId",
},
{
  name: "Status",
  value: "data.lastCallback.endpoint",
  type: "select",
  options: [
    { value: "approved", label: "Approved" },
    { value: "canceled", label: "Canceled" },
    { value: "declined", label: "Declined" },
    { value: "disbursed", label: "Disbursed" },
    { value: "document-signed", label: "Document Signed" },
    {
      value: "ready-for-disbursement",
      label: "Ready For Disbursement",
    },
  ],
},
{
  name: "Country",
  value: "data.country",
  type: "select",
  options: [
    { value: "SE", label: "Sweden" },
  ],
},
{
  name: "Product",
  value: "data.agentApiApplication.application.product",
  type: "select",
  options: [
    { value: "Loans", label: "Loans" },
    { value: "CreditCards", label: "Cards" },
  ],
},
{
  name: "Applicant's First Name",
  value: "data.agentApiApplication.applicant.firstName",
  queryOperator: "contains",
},
{
  name: "Applicant's Last Name",
  value: "data.agentApiApplication.applicant.lastName",
  queryOperator: "contains",
},
{
  name: "Applicant's National ID",
  value: "data.agentApiApplication.applicant.nationalId",
},
{
  name: "Applicant's Phone Number",
  value: "data.agentApiApplication.applicant.mobileNumber",
},
{
  name: "Applicant's E-mail",
  value: "data.agentApiApplication.applicant.emailAddress",
},
{
  name: "Co applicant's First Name",
  value: "data.agentApiApplication.applicant.firstName",
  queryOperator: "contains",
},
{
  name: "Co applicant's Last Name",
  value: "data.agentApiApplication.applicant.lastName",
  queryOperator: "contains",
},
{
  name: "Co applicant's National ID",
  value: "data.agentApiApplication.applicant.nationalId",
},
{
  name: "Co applicant's Phone Number",
  value: "data.agentApiApplication.applicant.mobileNumber",
},
{
  name: "Co applicant's E-mail",
  value: "data.agentApiApplication.applicant.emailAddress",
},
{
  name: "Campaign Id",
  value: "data.agentApiApplication.campaignId",
},
{
  name: "Business Status",
  value: "data.businessStatus",
  type: "select",
  options: [
    { value: "Active", label: "Active" },
    { value: "awaiting-accept", label: "Awaiting-accept" },
    { value: "customer-information", label: "Customer-information" },
    { value: "tink-consent", label: "Tink-consent" },
    { value: "insurance-sale", label: "Insurance-sale" },
    { value: "manual-pep-review", label: "Manual-pep-review" },
    { value: "manual-bgc-handling", label: "Manual-bgc-handling" },
    { value: "canceled-payput-approval", label: "Canceled-payout-approval" },
    { value: "manual-payout-approval", label: "Manual-payout-approval" },
    { value: "canceled-id-check", label: "Canceled-id-check" },
    { value: "canceled-pep-check", label: "Canceled-pep-check" },
    { value: "declined-bgc", label: "Declined-bgc" },
    { value: "declined-cross-check", label: "Declined-cross-check" },
    { value: "declined-final-disbursement", label: "Declined-final-disbursement" },
    { value: "declined-payment-verification", label: "Declined-payment-verification" },
    { value: "loan-disbursed", label: "Loan-disbursed" },
    { value: "ready-for-disbursement", label: "Ready-for-disbursement" },
    { value: "cross-data-check", label: "Cross-data-check" },
    { value: "manual-check-final", label: "Manual-check-final" },
    { value: "signicat-authentication", label: "Signicat-authentication" },
  ],
},
{
  name: "Created after",
  value: "createdAt",
  type: "date",
  queryOperator: "gte",
  max: format(Date.now(), "yyyy-MM-dd"),
},
{
  name: "Created before",
  value: "createdAt",
  type: "date",
  queryOperator: "lt",
  max: format(Date.now(), "yyyy-MM-dd"),
}]

const searchFields = country === "SE" ? searchFieldsSweden : searchFieldsSpain

export const searchConfiguration = {
  features: [FEATURES.CaseNotes],
  searchProcesses: ["loan-es", "loan-es-credit-decision"],
  searchFields: searchFields,
  searchResultFields: [
    { name: "Country", value: "data.country", mapping: (value: string) => `${value === "ES" ? "🇪🇸 ES" : value === "SE" ? "🇸🇪 SE" : value}`, },
    { name: "Flow Application ID", value: "flowId" },
    { name: "Agent Used", value: "data.agentApiApplication.agentId" },
    {
      name: "Applicant's First Name",
      value: "data.agentApiApplication.applicant.firstName",
    },
    {
      name: "Applicant's Last Name",
      value: "data.agentApiApplication.applicant.lastName",
    },
    {
      name: "Applicant's Phone Number",
      value: "data.agentApiApplication.applicant.mobileNumber",
    },
    {
      name: "Applicant's E-mail",
      value: "data.agentApiApplication.applicant.emailAddress",
    },

    {
      name: "Co applicant's First Name",
      value: "data.agentApiApplication.coapplicant.firstName",
      process: ["common-process"]
    },
    {
      name: "Co applicant's Last Name",
      value: "data.agentApiApplication.coapplicant.lastName",
      process: ["common-process"]
    },
    {
      name: "Campaign Id",
      value: "data.agentApiApplication.application.campaignId",
      mapping: (value: any) => value.toString(),
      process: ["common-process"]
    },

    {
      name: "Campaign Id",
      value: "data.agentApiApplication.campaignId",
      mapping: (value: any) => value.toString(),
    },
    { name: "Status", value: "data.lastCallback.endpoint" },
    {
      name: "Created",
      value: "createdAt",
      type: "date",
    },
  ],
  search: {
    sort: [
      {
        name: "Recently changed",
        value: "updatedAt",
        direction: "desc",
      },
      { name: "Oldest", value: "createdAt", direction: "asc" },
      { name: "Newest", value: "createdAt", direction: "desc" },
    ],
  },
  searchQueryView: "caseworker-search-result", // defined in setup/process/common/views/caseworker-search-result.js
}

