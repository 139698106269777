import type { FC, SVGProps } from "react";

export type Queue = {
    label: string;
    name: string;
    queueFilter: string;
    queueType?: string;
    queueSort: string;
    pollingInterval: number;
    pollingEnabled?: boolean | true;
    limit: number;
    groupName: string;
  };
  
  export type QueueDefinition = {
    [key: string]: Queue;
  };

  export type MenuStructureItem = {
    name: string;
    elems: string[];
    isOpen?: boolean;
    icon?: FC<SVGProps<SVGSVGElement> & { size?: string | undefined; }>;
  }

  export enum groupNames {
    "All_APPLICATIONS" = "All applications",
    "CASEWORKER_TASKS" = "Caseworker tasks",
    "CUSTOMER_TASKS" = "Customer tasks",
    "INCIDENTS" = "Incidents",
    "CLEANUP" = "Cleanup",
  }