import { DecisionSectionWrapper, DecisionSectionDivWrapper } from "../styles";
import React, { useState } from "react";
import { Icons } from "@stacc/flow-ui-components";
import {
  UsertaskButtons,
  UsertaskApplicantInfo,
  UsertaskTextField,
} from "../UsertaskComponents";
import CommonUserTask from "../CommonUserTask";
import {
  ApplicantInfoWrapper,
  ApplicantDetailsPadding,
  ApplicantWrapper,
  InfoSectionApplicantInfoWrapper,
  ApplicantsWrapper,
  InfoWrapper,
} from "./styles";

const ChangeApplication = (flow: any) => {
  const [updatedFirstName, setUpdatedFirstName] = useState(
    flow.task?.data?.updatedFirstName ?? null,
  );
  const [updatedLastName, setUpdatedLastName] = useState(
    flow.task?.data?.updatedLastName ?? null,
  );
  const [updatedEmailAddress, setUpdatedEmailAddress] = useState(
    flow.task?.data?.updatedEmailAddress ?? null,
  );
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState(
    flow.task?.data?.updatedPhoneNumber ?? null,
  );

  const [updatedFirstNameCoApplicant, setUpdatedFirstNameCoApplicant] =
    useState(flow.task?.data?.updatedFirstNameCoApplicant ?? null);
  const [updatedLastNameCoApplicant, setUpdatedLastNameCoApplicant] = useState(
    flow.task?.data?.updatedLastNameCoApplicant ?? null,
  );
  const [updatedEmailAddressCoApplicant, setUpdatedEmailAddressCoApplicant] =
    useState(flow.task?.data?.updatedEmailAddressCoApplicant ?? null);
  const [updatedPhoneNumberCoApplicant, setUpdatedPhoneNumberCoApplicant] =
    useState(flow.task?.data?.updatedPhoneNumberCoApplicant ?? null);

  const [error, setError] = useState<string | null>(null);

  const data = flow.flow?.data;
  const applicant = data?.agentApiApplication?.applicant;
  const coapplicant = data?.agentApiApplication?.coapplicant;

  const infoRows = [
    { label: "First name", value: "firstName" },
    { label: "Last name", value: "lastName" },
    { label: "Email address", value: "emailAddress" },
    { label: "Phone number", value: "mobileNumber" },
  ];

  const canComplete =
    updatedFirstName ||
    updatedFirstNameCoApplicant ||
    updatedLastName ||
    updatedLastNameCoApplicant ||
    updatedEmailAddress ||
    updatedEmailAddressCoApplicant ||
    updatedPhoneNumber ||
    updatedPhoneNumberCoApplicant;

  const validate = () => {
    const isValid = true;
    // Validate that updatedEmailAddress is a valid email address & allow only 3 letter domains
    if (updatedEmailAddress) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,3}$/;
      if (!emailRegex.test(updatedEmailAddress)) {
        setError(
          "Please enter a valid email address for applicant & try again.",
        );
        return !isValid;
      }
    }

    if (updatedEmailAddressCoApplicant) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,3}$/;
      if (!emailRegex.test(updatedEmailAddressCoApplicant)) {
        setError(
          "Please enter a valid email address for Co-applicant & try again.",
        );
        return !isValid;
      }
    }

    // Validate that updatedPhoneNumber is a valid phone number with 8 digits or contains contry code of example +47 or 0047
    if (updatedPhoneNumber) {
      const phoneRegex = /^(\+[0-9]{2}|[0-9]{4})?[0-9]{9}$/;
      if (!phoneRegex.test(updatedPhoneNumber)) {
        setError(
          "Please enter a valid phone number for applicant & try again.",
        );
        return !isValid;
      }
    }

    if (updatedPhoneNumberCoApplicant) {
      const phoneRegex = /^(\+[0-9]{2}|[0-9]{4})?[0-9]{9}$/;
      if (!phoneRegex.test(updatedPhoneNumberCoApplicant)) {
        setError(
          "Please enter a valid phone number for Co-applicant & try again.",
        );
        return !isValid;
      }
    }

    return isValid;
  };

  const flowTrigger = async () => {
    const isValid = validate();
    if (!isValid) return;

    const options: {
      [key: string]: any;
    } = {
      firstName: updatedFirstName,
      lastName: updatedLastName,
      emailAddress: updatedEmailAddress,
      mobileNumber: updatedPhoneNumber,
      firstNameCoApplicant: updatedFirstNameCoApplicant,
      lastNameCoApplicant: updatedLastNameCoApplicant,
      emailAddressCoApplicant: updatedEmailAddressCoApplicant,
      mobileNumberCoApplicant: updatedPhoneNumberCoApplicant,
    };

    // Create a new options object without the null values
    Object.keys(options).forEach((key) => {
      if (options[key] == null) {
        delete options[key];
      }
    });

    await flow.trigger(options);
  };

  const renderInfoRow = (label: string, value: any) => {
    return (
      <ApplicantInfoWrapper>
        <ApplicantDetailsPadding>{label}</ApplicantDetailsPadding>
        <ApplicantDetailsPadding>{value}</ApplicantDetailsPadding>
      </ApplicantInfoWrapper>
    );
  };

  const renderApplicantInfo = (applicant: any, isMainApplicant = false) => {
    return (
      <ApplicantWrapper>
        <InfoSectionApplicantInfoWrapper>
          <div style={{ padding: "0 20px", fontSize: "12px" }}>
            {isMainApplicant ? "Main applicant" : "Co-applicant"}
          </div>
          <div style={{ padding: "0 20px", fontSize: "12px" }}>
            {applicant.nationalId}
          </div>
        </InfoSectionApplicantInfoWrapper>
        {infoRows.map((value) =>
          renderInfoRow(
            value.label,
            isMainApplicant ? applicant[value.value] : coapplicant[value.value],
          ),
        )}
      </ApplicantWrapper>
    );
  };

  const renderApplicantsInfo = () => {
    let applicants = [applicant];

    //If we have a coapplicant, render both applicants
    if (coapplicant) {
      applicants = [...applicants, coapplicant];
    }

    return (
      <ApplicantsWrapper>
        {applicants.map((applicant, key) => {
          return renderApplicantInfo(applicant, key === 0);
        })}
      </ApplicantsWrapper>
    );
  };

  //TODO: Create render functions for usertask components, when we have option to add a co-applicant
  //TODO: Add option to change either applicant or co-applicant or both

  const renderChangeApplicantFields = (isCoApplicant = false) => {
    return (
      <div>
        {UsertaskTextField(
          isCoApplicant ? updatedFirstNameCoApplicant : updatedFirstName,
          isCoApplicant
            ? (e) => setUpdatedFirstNameCoApplicant(e.target.value)
            : (e) => setUpdatedFirstName(e.target.value),
          "First name",
          "Enter a new first name",
        )}
        {UsertaskTextField(
          isCoApplicant ? updatedLastNameCoApplicant : updatedLastName,
          isCoApplicant
            ? (e) => setUpdatedLastNameCoApplicant(e.target.value)
            : (e) => setUpdatedLastName(e.target.value),
          "Last name",
          "Enter a new last name",
        )}
        {UsertaskTextField(
          isCoApplicant ? updatedEmailAddressCoApplicant : updatedEmailAddress,
          isCoApplicant
            ? (e) => setUpdatedEmailAddressCoApplicant(e.target.value)
            : (e) => setUpdatedEmailAddress(e.target.value),
          "Email address",
          "Enter a new email address",
        )}
        {UsertaskTextField(
          isCoApplicant ? updatedPhoneNumberCoApplicant : updatedPhoneNumber,
          isCoApplicant
            ? (e) => setUpdatedPhoneNumberCoApplicant(e.target.value)
            : (e) => setUpdatedPhoneNumber(e.target.value),
          "Phone number",
          "Enter a new phone number",
        )}
      </div>
    );
  };

  const renderChangeApplicationFields = () => {
    const [showChangeApplicationFields, setShowChangeApplicationFields] =
      useState(false);
    const [
      showChangeApplicationFieldsCoApplicant,
      setShowChangeApplicationFieldsCoApplicant,
    ] = useState(false);

    if (coapplicant) {
      return (
        <>
          <DecisionSectionDivWrapper
            onClick={() =>
              setShowChangeApplicationFields(!showChangeApplicationFields)
            }
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              cursor: "pointer",
            }}
          >
            <div>
              {applicant.firstName} {applicant.lastName}
            </div>
            <div>
              {!showChangeApplicationFields ? (
                <Icons.ChevronDown />
              ) : (
                <Icons.ChevronUp />
              )}
            </div>
          </DecisionSectionDivWrapper>
          {showChangeApplicationFields ? (
            <>{renderChangeApplicantFields()}</>
          ) : null}
          <DecisionSectionDivWrapper
            onClick={() =>
              setShowChangeApplicationFieldsCoApplicant(
                !showChangeApplicationFieldsCoApplicant,
              )
            }
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              cursor: "pointer",
            }}
          >
            <div>
              {coapplicant.firstName} {coapplicant.lastName}
            </div>
            <div>
              {!showChangeApplicationFieldsCoApplicant ? (
                <Icons.ChevronDown />
              ) : (
                <Icons.ChevronUp />
              )}
            </div>
          </DecisionSectionDivWrapper>
          {showChangeApplicationFieldsCoApplicant ? (
            <>{setShowChangeApplicationFieldsCoApplicant(true)}</>
          ) : null}
        </>
      );
    }

    return <>{renderChangeApplicantFields()}</>;
  };

  const changeApplicationContent = () => {
    return (
      <>
        <InfoWrapper>{renderApplicantsInfo()}</InfoWrapper>
        <DecisionSectionWrapper>
          {UsertaskApplicantInfo(applicant)}
          {coapplicant ? (
            <>{UsertaskApplicantInfo(coapplicant, false)}</>
          ) : null}

          {renderChangeApplicationFields()}

          {UsertaskButtons(canComplete, flowTrigger, () =>
            flow.save({
              updatedFirstName: updatedFirstName,
              updatedLastName: updatedLastName,
              updatedEmailAddress: updatedEmailAddress,
              updatedPhoneNumber: updatedPhoneNumber,
              updatedFirstNameCoApplicant: updatedFirstNameCoApplicant,
              updatedLastNameCoApplicant: updatedLastNameCoApplicant,
              updatedEmailAddressCoApplicant: updatedEmailAddressCoApplicant,
              updatedPhoneNumberCoApplicant: updatedPhoneNumberCoApplicant,
            }),
          )}
          {!error ? null : (
            <DecisionSectionDivWrapper
              style={{
                display: "flex",
                backgroundColor: "#e57373",
                justifyContent: "center",
                boxShadow:
                  "0px 15px 40px rgb(184 184 184 / 20%), 0px 5px 10px rgb(184 184 184 / 5%)",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  gridGap: "5px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {error}
              </span>
            </DecisionSectionDivWrapper>
          )}
        </DecisionSectionWrapper>
      </>
    );
  };

  return CommonUserTask(changeApplicationContent());
};

export default ChangeApplication;
