import { ApplicantInfo } from "./ApplicantInfo";
import { ApplicantCollectionContainer } from "./style";

import type { Applicant } from "./types";

/**
 * Renders a collection of applicant information.
 * @component
 * @param {Object} props - The component props.
 * - applicant - The main applicant object.
 * - coapplicant - The co-applicant object (optional).
 * @returns {JSX.Element} The rendered component.
 */
export const ApplicantCollection = (applicant: Applicant) => {
  const renderApplicantInfo = (applicant: Applicant) => {
    return <ApplicantInfo applicant={{ ...applicant }} />;
  };

  return (
    <ApplicantCollectionContainer>
      {renderApplicantInfo(applicant)}
    </ApplicantCollectionContainer>
  );
};
