import type { FormDataErrors, FormData } from "./types";

function isNumber(value: string) {
  return !Number.isNaN(Number(value));
}
export const validateFormData = (formData: FormData) => {
  // Validate the form and make sure we are sending in numbers
  const errors: FormDataErrors = {
    loanAmount: { hasError: false },
    refinanceAmount: { hasError: false },
    loanDuration: { hasError: false },
    interestRate: { hasError: false },
    comment: { hasError: false }
  };
  if (isNumber(formData.loanAmount) === false) {
    errors.loanAmount = {
      hasError: true,
      message: 'Loan amount is not a number'
    }
  }

  if (isNumber(formData.refinanceAmount) === false) {
    errors.refinanceAmount = {
      hasError: true,
      message: "Refinance amount is not a number"
    }
  }

  if (isNumber(formData.loanDuration) === false) {
    errors.loanDuration = {
      hasError: true,
      message: "Loan duration is not a number"
    }
  }
  if (isNumber(formData.interestRate) === false) {
    errors.interestRate = {
      hasError: true,
      message: "Interest rate is not a number"
    }
  }
  return errors
}