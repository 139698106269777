/* eslint-disable */
import React from "react";
import Branding from "../branding/Branding";
import LoginLogo from "../branding/LoginLogo";
import MainLogo from "../branding/MainLogo";
import translatesInbox from "../translate/inbox.json";
import { CaseManager, APPS, FEATURES } from "@flow/case-manager";
import * as case_and_queue_config from "./case-and-queue-config";
import { searchConfiguration } from "./search-configuration";
import { Header } from "../branding/Header";


export const Portal = () => {

  const leaBankTheme = {
    mainLogo: () => {
      return <Header />;
    },
    branding: Branding,
    customerIcon: LoginLogo,
  };


  const customTranslations = [
    {
      app: APPS.Inbox,
      language: "en",
      translates: translatesInbox,
    },
  ];
  return (
    <CaseManager
      configuration={() => {
        return {
          homeUrl: "/inbox",
          language: "en",
          skin: leaBankTheme,
          apps: [APPS.Inbox, APPS.Search, APPS.Process],
          inboxConfiguration: {
            features: [FEATURES.CaseNotes],
            searchFields: ["data.agentApiApplication.agentId"],
            queuesPollingEnabled: true,
            startableProcesses: ["loan-es", "manual-cleanup", "common-process"],
          },
          searchConfiguration: searchConfiguration,
          caseNotesConfiguration: {
            sort: "DESC",
          },
          customComponents: case_and_queue_config.CustomComponents(),
          translates: customTranslations,
        };
      }}
    />
  );
};
