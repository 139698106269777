const commonTemplate = {
  greeting: "¡Hola, {{firstname}}!\n\n",
  closing: "\n\nSaludos, Lea bank.",
  thanks: "\n\n¡Gracias por elegirnos!",
};

const swedishTemplate = {
  greeting: "Hej, {{firstname}}!\n\n",
  closing: "\n\nVänliga hälsningar från Lea Bank",
  openingTimes: "\n\nVåra öppettider är måndag-fredag kl.09-15.",
  offer: `Lånebelopp: {{finalLoanAmount}}\nAvbetalning: {{monthlyCost}}\nÅterbetalningstid: {{terms}}\nKostnader: {{termFee}}\nKontots öppningsavgift: {{startupFee}}\nNominell ränta: {{interestRate}}\nEffektiv ränta: {{effectiveInterestRate}}`,
};

export const communicationTemplates: {
  [key: string]: {
    templateName: string;
    text: string;
    variables: string[];
  };
} = {
  PRE_APPROVAL: {
    templateName: "Pre-approval",
    text: `${commonTemplate.greeting}Tu solicitud de préstamo ha sido preautorizada.\n\nHaz clic en el siguiente enlace para completar tu solicitud: {{signinglink}}${commonTemplate.thanks}${commonTemplate.closing}`,
    variables: ["firstname", "signinglink"],
  },
  SIGNATURE_REMINDER: {
    templateName: "Signature reminder after ID check",
    text: `${commonTemplate.greeting}¡Todo listo!, tu contrato de préstamo está preparado para que lo puedas firmar.\n\nHaz clic en el siguiente enlace para completar tu solicitud: {{signinglink}}${commonTemplate.thanks}${commonTemplate.closing}`,
    variables: ["firstname", "signinglink"],
  },
  APPLICATION_DECLINED: {
    templateName: "Application declined",
    text: `${commonTemplate.greeting}Después de valorar detenidamente toda la información que nos has facilitado, no nos es posible continuar con tu solicitud de préstamo en este momento.${commonTemplate.closing}`,
    variables: ["firstname"],
  },
  APPLICATION_APPROVED_REMINDER: {
    templateName: "Reminder approved application",
    text: `${commonTemplate.greeting}Solo falta unos pocos clics para recibir tu préstamo, no te llevara mucho tiempo.\n\nHaz clic en el siguiente enlace para completar tu solicitud: {{signinglink}}${commonTemplate.thanks}${commonTemplate.closing}`,
    variables: ["firstname", "signinglink"],
  },
  DISBURSEMENT_CONFIRMATION: {
    templateName: "Disbursement confirmation",
    text: `${commonTemplate.greeting}¡Enhorabuena! Ya hemos realizado la transferencia del préstamo a tu cuenta bancaria. ¡Queremos darte la bienvenida a Lea bank!\n\nTu dinero estará disponible entre 1 y 2 días hábiles.${commonTemplate.thanks}${commonTemplate.closing}`,
    variables: ["firstname"],
  },
};

export const mailTemplatesSE: {
  [key: string]: {
    templateName: string;
    text: string;
    variables: string[];
    templateType?: "sms" | "mail";
  };
} = {
  GENERAL_TEMPLATE: {
    templateName: "General template",
    text: `${swedishTemplate.greeting}Länk till din ansökan: {{signingLink}}.\n\nOm du har frågor kan du kontakta oss på 08 509 285 00 eller kundservice@leabank.se\n\nÖppettiderna för vårt kundcenter är 08:00 till 15:00 måndag till fredag.\n\nVi önskar dig en fortsatt trevlig dag.${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "mail",
  },
  REJECTION: {
    templateName: "Rejection",
    text: `${swedishTemplate.greeting}Vi har nu gjort en helhetsbedömning av din låneansökan och tyvärr kan vi inte hjälpa till med finansieringen den här gången.${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "mail",
  },
  PRE_APPROVAL: {
    templateName: "Pre-approval",
    text: `${swedishTemplate.greeting}
      Vi är glada att kunna erbjuda dig följande:\n\n
      ${swedishTemplate.offer}
      \n\nLänk till din ansökan: {{signingLink}}
      \n\nSå snart ansökan är färdigbehandlad kommer lånet att betalas ut till det angivna bankkontot.
      \n\nOm du har uppgett att lånet ska användas för att refinansiera andra lån är det viktigt att du själv betalar lånen.
      \n\nOm du har frågor kan du kontakta oss på 08 509 285 00 eller kundservice@leabank.se
      \n\nÖppettiderna för vårt kundcenter är 08:00 till 15:00 måndag till fredag.${swedishTemplate.closing}
      `,
    variables: ["firstname", "signingLink", "finalLoanAmount", "monthlyCost", "terms", "interestRate", "effectiveInterestRate", "startupFee", "termFee"],
    templateType: "mail",
  },
  PRE_APPROVAL_TOPUP: {
    templateName: "Pre-approval - TopUp",
    text: `${swedishTemplate.greeting}
      Du har ansökt om utökat lån hos oss.\n\n
      Nu har vi behandlat din ansökan preliminärt och baserat på uppgifterna du har angett kan vi erbjuda dig följande:\n\n
      ${swedishTemplate.offer}
      \n\nEftersom du bara kan ha ett lån hos Lea Bank löser vi in ditt befintliga lån med ett nytt lån och betalar ut mellanskillnaden för dessa lån till ditt konto.
      \n\nLänk till din ansökan: {{signingLink}}
      \n\nSå fort din ansökan har behandlats för vi över lånet till det konto som du har angett, såvida uppgifterna i ansökan stämmer. Låneerbjudandet är giltigt i 30 dagar.
      \n\nOm du har frågor kan du kontakta oss genom att ringa 08 509 285 00 eller skicka e-post till kundservice@leabank.se
      \n\nKundtjänsten är öppen från måndag till fredag kl. 08:00–15:00.${swedishTemplate.closing}
      `,
    variables: ["firstname", "signingLink", "finalLoanAmount", "monthlyCost", "terms", "interestRate", "effectiveInterestRate", "startupFee", "termFee"],
    templateType: "mail",
  },
  MISSING_ERROR_ACCOUNT_NUMBER: {
    templateName: "Missing or error in account number",
    text: `${swedishTemplate.greeting}
      Det angivna kontonumret är felaktigt eller saknas. Vänligen uppdatera din ansökan med korrekt clearing- och kontonummer (endast siffror) och kontakta oss när det är gjort.
      \n\nLänk till din ansökan: {{signingLink}}
      \n\nOm du har frågor kan du kontakta oss på 08 509 285 00 eller kundservice@leabank.se
      Öppettiderna för vårt kontaktcenter är 08:00 till 15:00 måndag till fredag. 
      Vi önskar dig en fortsatt trevlig dag.
      ${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "mail",
  },
  PRE_APPROVAL_SMS: {
    templateName: "Pre-approval",
    text: `${swedishTemplate.greeting}Vi har skickat ett preliminärt låneerbjudande till dig via e-post. Kontakta oss om du har frågor gällande din ansökan.\n\nLänk till din låneansökan: {{signingLink}}${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "sms",
  },
  CONTROL_CALL: {
    templateName: "Control call",
    text: `${swedishTemplate.greeting}Vi behöver ställa dig några snabba frågor innan eventuellt lån kan betalas ut. Vänligen ta kontakt med oss på 0850928500, tack!${swedishTemplate.openingTimes}${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "sms",
  },
  CONTROL_CALL_INSURANCE: {
    templateName: "Control call - Insurance",
    text: `${swedishTemplate.greeting}Vi behöver ställa dig några snabba frågor innan eventuellt lån kan betalas ut. Vänligen ta kontakt med oss på 0812439619, tack!${swedishTemplate.openingTimes}${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "sms",
  },
  MYMONEY_INSURANCE: {
    templateName: "MyMoney - Insurance call",
    text: `${swedishTemplate.greeting}Vi har försökt nå dig gällande ditt lån hos oss. Vänligen ring oss på telefonnummer 0812439619.${swedishTemplate.openingTimes}${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "sms",
  },
  TOPUP: {
    templateName: "TopUp",
    text: `${swedishTemplate.greeting}Vi har skickat ett preliminärt låneerbjudande till dig gällande utökning av ditt lån. Kontakta oss om du har frågor gällande din ansökan.\n\nLänk till din låneansökan: {{signingLink}}${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "sms",
  },
  EMAIL_SENT: {
    templateName: "We have sent an email",
    text: `${swedishTemplate.greeting}Vi har skickat e-post till dig gällande din låneansökan. Om du inte hittar det i din inkorg, kolla spam.\n\nHa en fortsatt trevlig dag!${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "sms",
  },
  MISSING_ERROR_ACCOUNT_NUMBER_SMS: {
    templateName: "Missing or error in account number",
    text: `${swedishTemplate.greeting}Det angivna kontonumret är felaktigt eller saknas. Vänligen uppdatera din ansökan med korrekt clearing- och kontonummer (endast siffror) och kontakta oss när det är gjort.\n\nLänk till din låneansökan: {{signingLink}}${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "sms",
  }
}

export const mailTemplatesCardSE: {
  [key: string]: {
    templateName: string;
    text: string;
    variables: string[];
    templateType?: "sms" | "mail";
  };
} = {
  GENERAL_TEMPLATE: {
    templateName: "General template",
    text: `${swedishTemplate.greeting}Länk till din ansökan: {{signingLink}}.\n\nOm du har frågor kan du kontakta oss på 08 509 285 00 eller kundservice@leabank.se\n\nÖppettiderna för vårt kundcenter är 08:00 till 15:00 måndag till fredag.\n\nVi önskar dig en fortsatt trevlig dag.${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "mail",
  },
  REJECTION: {
    templateName: "Rejection",
    text: `${swedishTemplate.greeting}Vi har nu gjort en helhetsbedömning av din låneansökan och tyvärr kan vi inte hjälpa till med finansieringen den här gången.${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "mail",
  },
  PRE_APPROVAL: {
    templateName: "Pre-approval",
    text: `${swedishTemplate.greeting}
      Vi är glada att kunna erbjuda dig följande:\n\n
      ${swedishTemplate.offer}
      \n\nLänk till din ansökan: {{signingLink}}
      \n\nSå snart ansökan är färdigbehandlad kommer lånet att betalas ut till det angivna bankkontot.
      \n\nOm du har uppgett att lånet ska användas för att refinansiera andra lån är det viktigt att du själv betalar lånen.
      \n\nOm du har frågor kan du kontakta oss på 08 509 285 00 eller kundservice@leabank.se
      \n\nÖppettiderna för vårt kundcenter är 08:00 till 15:00 måndag till fredag.${swedishTemplate.closing}
      `,
    variables: ["firstname", "signingLink", "finalLoanAmount", "monthlyCost", "terms", "interestRate", "effectiveInterestRate", "startupFee", "termFee"],
    templateType: "mail",
  },
  PRE_APPROVAL_TOPUP: {
    templateName: "Pre-approval - TopUp",
    text: `${swedishTemplate.greeting}
      Du har ansökt om utökat lån hos oss.\n\n
      Nu har vi behandlat din ansökan preliminärt och baserat på uppgifterna du har angett kan vi erbjuda dig följande:\n\n
      ${swedishTemplate.offer}
      \n\nEftersom du bara kan ha ett lån hos Lea Bank löser vi in ditt befintliga lån med ett nytt lån och betalar ut mellanskillnaden för dessa lån till ditt konto.
      \n\nLänk till din ansökan: {{signingLink}}
      \n\nSå fort din ansökan har behandlats för vi över lånet till det konto som du har angett, såvida uppgifterna i ansökan stämmer. Låneerbjudandet är giltigt i 30 dagar.
      \n\nOm du har frågor kan du kontakta oss genom att ringa 08 509 285 00 eller skicka e-post till kundservice@leabank.se
      \n\nKundtjänsten är öppen från måndag till fredag kl. 08:00–15:00.${swedishTemplate.closing}
      `,
    variables: ["firstname", "signingLink", "finalLoanAmount", "monthlyCost", "terms", "interestRate", "effectiveInterestRate", "startupFee", "termFee"],
    templateType: "mail",
  },
  MISSING_ERROR_ACCOUNT_NUMBER: {
    templateName: "Missing or error in account number",
    text: `${swedishTemplate.greeting}
      Det angivna kontonumret är felaktigt eller saknas. Vänligen uppdatera din ansökan med korrekt clearing- och kontonummer (endast siffror) och kontakta oss när det är gjort.
      \n\nLänk till din ansökan: {{signingLink}}
      \n\nOm du har frågor kan du kontakta oss på 08 509 285 00 eller kundservice@leabank.se
      Öppettiderna för vårt kontaktcenter är 08:00 till 15:00 måndag till fredag. 
      Vi önskar dig en fortsatt trevlig dag.
      ${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "mail",
  },
  PRE_APPROVAL_SMS: {
    templateName: "Pre-approval",
    text: `${swedishTemplate.greeting}Vi har skickat ett preliminärt låneerbjudande till dig via e-post. Kontakta oss om du har frågor gällande din ansökan.\n\nLänk till din låneansökan: {{signingLink}}${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "sms",
  },
  CONTROL_CALL: {
    templateName: "Control call",
    text: `${swedishTemplate.greeting}Vi behöver ställa dig några snabba frågor innan eventuellt lån kan betalas ut. Vänligen ta kontakt med oss på 0850928500, tack!${swedishTemplate.openingTimes}${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "sms",
  },
  CONTROL_CALL_INSURANCE: {
    templateName: "Control call - Insurance",
    text: `${swedishTemplate.greeting}Vi behöver ställa dig några snabba frågor innan eventuellt lån kan betalas ut. Vänligen ta kontakt med oss på 0812439619, tack!${swedishTemplate.openingTimes}${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "sms",
  },
  MYMONEY_INSURANCE: {
    templateName: "MyMoney - Insurance call",
    text: `${swedishTemplate.greeting}Vi har försökt nå dig gällande ditt lån hos oss. Vänligen ring oss på telefonnummer 0812439619.${swedishTemplate.openingTimes}${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "sms",
  },
  TOPUP: {
    templateName: "TopUp",
    text: `${swedishTemplate.greeting}Vi har skickat ett preliminärt låneerbjudande till dig gällande utökning av ditt lån. Kontakta oss om du har frågor gällande din ansökan.\n\nLänk till din låneansökan: {{signingLink}}${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "sms",
  },
  EMAIL_SENT: {
    templateName: "We have sent an email",
    text: `${swedishTemplate.greeting}Vi har skickat e-post till dig gällande din låneansökan. Om du inte hittar det i din inkorg, kolla spam.\n\nHa en fortsatt trevlig dag!${swedishTemplate.closing}`,
    variables: ["firstname"],
    templateType: "sms",
  },
  MISSING_ERROR_ACCOUNT_NUMBER_SMS: {
    templateName: "Missing or error in account number",
    text: `${swedishTemplate.greeting}Det angivna kontonumret är felaktigt eller saknas. Vänligen uppdatera din ansökan med korrekt clearing- och kontonummer (endast siffror) och kontakta oss när det är gjort.\n\nLänk till din låneansökan: {{signingLink}}${swedishTemplate.closing}`,
    variables: ["firstname", "signingLink"],
    templateType: "sms",
  }
}