import type { CommonData } from "../../../../types/common";
import { NormalInfoWrapper } from "../../../tasks/styles";
import { RowsWrapper } from "../../styles";

interface OfferDetailsProps {
  data: CommonData
}

interface Detail {
  label: string,
  value: any,
}

export const ExistingLoanDetailsRow = ({ data }: OfferDetailsProps) => {
  const { ownExperience } = data?.integrations?.leabank ?? {};
  const { accountStatus } = ownExperience?.data?.mainApplicant ?? {};

  function getIndexOfLastRow(details: Detail[]) {
    let lastValidRow = -1;
    let secondLastValidRow = -1;
    let validCount = 0;

    for (let i = 0; i < details.length; i++) {
      if (details[i].value != null) {
        secondLastValidRow = lastValidRow;
        lastValidRow = i;
        validCount++;
      }
    }

    return validCount % 2 === 0
      ? [secondLastValidRow, lastValidRow]
      : [lastValidRow];
  }

  const existingLoanDetails = [
    {
      label: "Top Up",
      value: "Yes",
    },
    {
      label: "Core number",
      value: accountStatus?.coreNo,
    },
    {
      label: "Current loan amount",
      value: `${new Intl.NumberFormat().format(accountStatus?.openInvoices)} kr`,
    },
    {
      label: "Current nominal interest rate",
      value: `${accountStatus?.currentInt} %`,
    },
    {
      label: "Amount overdue",
      value: `${accountStatus?.amtOverDue} kr`,
    },
    {
      label: "Days past due (DPD) as of today",
      value: `${accountStatus?.dpd}
          ${accountStatus?.dpd === 1 ? " day" : " days"}`,
    },
    {
      label: "Number of active loans",
      value: accountStatus?.activeLoansCount,
    },
  ]

  const lastIndexes = getIndexOfLastRow(existingLoanDetails)
  return (
    <RowsWrapper className="Existing loan information">
      <NormalInfoWrapper $header>Existing Loan Details</NormalInfoWrapper>
      {existingLoanDetails.map((detail, index) => {
        if (detail.value === null || detail.value === undefined) return
        return (
          <NormalInfoWrapper key={`existing-loan- ${detail.label}`} $isLast={lastIndexes.includes(index)}>
            <div>{detail.label}</div>
            <div>{detail.value}</div>
          </NormalInfoWrapper>
        )
      })}

    </RowsWrapper>
  )
}