import { hasAccessRoles } from "../utils/userHelper";
import { groupNames, type Queue } from "./types";

const pollingInterval = 60000;
const allowedRoles = [ "admin-es","admin-se","admin-no", "admin-fi","reader-es","reader-se","reader-no","reader-fi"];

function hasAccessToMenu(userRoles: string[], overwriteAccessRoles?: string[]): boolean {
  return hasAccessRoles(userRoles, overwriteAccessRoles ??  allowedRoles);
}

function queueCleanupProcess(userRoles: string[]): Queue | null {
    const name = "cleanup-process";
    if (hasAccessToMenu(userRoles)) {
        return {
            label: "Nightly cleanup job",
            name: name,
            queueFilter: "?flowDefinitionId=cleanup&view=queue-items",
            pollingEnabled: false,
            limit: 20,
            pollingInterval: pollingInterval,
            queueSort: "sort=updatedAt&dir=-1",
            groupName: groupNames.CLEANUP,
        };
    }
    return null;
}


function queueManualCleanupProcess(userRoles: string[]): Queue | null {
    const name = "manual-cleanup-process";
    if (hasAccessToMenu(userRoles)) {
        return {
            label: "Manual cleanup",
            name: name,
            queueFilter: "?flowDefinitionId=manual-cleanup&view=queue-items",
            queueSort: "sort=updatedAt&dir=-1",
            pollingEnabled: false,
            limit: 20,
            pollingInterval: pollingInterval,
            groupName: groupNames.CLEANUP
        }
    }
    return null;
}

function queues(userRoles: string[]): (Queue | null)[] {
  return [
    queueCleanupProcess(userRoles),
    queueManualCleanupProcess(userRoles)
  ];
}

export function createQueueDefinitionsCleanup(userRoles: string[]): { [key: string]: Queue } {
  const validQueues = queues(userRoles).filter((queue): queue is Queue => queue !== null);
  const queueDefinitions = validQueues.reduce((acc: { [key: string]: Queue }, queue) => {
    acc[queue.name] = queue;
    return acc;
  }
  , {});
return queueDefinitions;
}

export function createMenuDefinitionsCleanup(userRoles: string[]):  Queue[] {
  const validQueues = queues(userRoles).filter((queue): queue is Queue => queue !== null);
return validQueues;
}