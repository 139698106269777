import { FormTextArea } from "../../../components/Input/FormTextArea";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import type { ComponentConfig } from "../types";
import { useState } from 'react';
import ErrorMessages from "../../../components/ErrorMessages/ErrorMessage";
import { ApplicantInfo } from "../../../components/Applicant/ApplicantInfo";
import { Icons } from "@stacc/flow-ui-components";
import { ApplicantDetailsLabel } from "../manual-check-final/styles";
import { InfoRow } from "../../../components/InfoRow/InfoRow";
import { SelectGroup } from "../../../components/Select/SelectGroup";

const ManualInsuranceSale = (props: any) => {
  const { flow } = props;
  const { finalLoanAmount, interestRate, effectiveInterestRate, maxLoanAmount, minLoanAmount, 
    startupFee, termFee, terms, monthlyCost, refinanceAmount, topUpAmount, cashPayoutAmount, insuranceCost, insuranceSelected
  } = flow?.data?.offer || {};
  const loadedComment = props?.task?.data?.comment || "";
  const hasCoApplicant = !!flow?.data?.agentApiApplication?.coapplicant

  // States
  const [insuranceSale, setInsuranceSale] = useState(props.task?.data?.insuranceSale ?? insuranceSelected ?? null);
  const [comment, setComment] = useState<string>(loadedComment);
  const [errorMessages, setErrorMessages] = useState<string>("");

  const handleInsuranceSaleChange = (value: any) => {
    const insuranceSaleValue = (value === "true");
    setInsuranceSale(insuranceSaleValue)
  }

  const applicantInfo = flow?.data?.agentApiApplication?.applicant
  const coApplicantInfo = flow?.data?.agentApiApplication?.coapplicant ? {
    ...flow?.data?.agentApiApplication?.coapplicant,
    isCoApplicant: true,
  } : null;
  
  const offerGivenIcon = finalLoanAmount ? (
    <Icons.SuccessCircle />
  ) : flow?.caseData?.status === "active" ? (
    <Icons.ActiveCircle />
  ) : (
    <Icons.AbortedCircle />
  );

  const applicationDetailsOptions = [
    { label: "Final loan amount", value: finalLoanAmount },
    ...(topUpAmount ? [{ label: "Internal loan amount", value: topUpAmount }] : []),
    { label: "Cash payout amount", value: cashPayoutAmount },
    { label: "Refinance amount", value: refinanceAmount },
    { label: "Interest rate", value: interestRate },
    { label: "Maximum loan amount", value: maxLoanAmount },
    { label: "Minimum loan amount", value: minLoanAmount },
    { label: "Startup fee", value: startupFee },
    { label: "Term fee", value: termFee },
    { label: "Terms", value: terms },
    { label: "Effective interest rate", value: effectiveInterestRate },
    { label: "Monthly cost", value: monthlyCost },
    { label: "Offer given", value: offerGivenIcon },
    { label: "Insurance Cost", value: `${new Intl.NumberFormat().format(insuranceCost ?? 0)} kr` },
    { label: "Insurance Choosen", value: insuranceSelected ? "Yes" : "No" },
  ];

  const onComplete = () => {
    const formData = {
      comment,
      insuranceSale,
    };

    props.trigger(
      formData,
      () => { },
      (e: any) => {
        console.log(e);
        setErrorMessages(e?.detail);
      },
    );
  }
  const onSave = () => {
    const formData = {
      comment,
      insuranceSale,
    };
    props.save(formData,
      () => console.log("success"),
      (e: any) => {
        console.log(e);
        setErrorMessages(e?.detail);
      },
    )
  }

  const formConfig: ComponentConfig[] = [
    {
      component: (
        <div style={{
          "display": "flex",
          "flexDirection": "column",
          "gap": "1rem"
        }}>
          <ApplicantInfo applicant={applicantInfo} />
          {hasCoApplicant && <ApplicantInfo applicant={coApplicantInfo} />}
        </div>
      ),
      order: 1,
    },
    // Application details
    {
      component: (
        <>
          <ApplicantDetailsLabel>Application details</ApplicantDetailsLabel>
          <div style={{
            padding: "0 28px"
          }}>
            {applicationDetailsOptions.map((option, index) => (
              <InfoRow value={option.value} title={option.label} key={`infoRow${option.label}`} hasMatch={false} hasPadding={false} />
            ))}
          </div>
        </>
      ),
      order: 2
    },
    {
      component: (
        <SelectGroup
            title="Insurance sale"
            id="insuranceSale"
            value={insuranceSale}
            options={[
              { label: "Without insurance", value: false },
              { label: "With insurance", value: true },
            ]}
            onChange={(value: any) => handleInsuranceSaleChange(value)}
          />
      ),
      order: 3
    },
    {
      component: (
        <FormTextArea
          name="Comment"
          onChange={(e: string) => setComment(e)}
          label="Comment"
          defaultValue={comment || ""}
          placeholder="Other applicant information"
        />
      ),
      order: 4,
    },
    {
      component: <ErrorMessages errors={[errorMessages]} />,
      order: 5,
      hide: errorMessages === "",
    },
  ];

  return (
    <CommonUserTaskNew
      handleComplete={onComplete}
      handleSave={onSave}
      formConfig={formConfig}
      overrideMasonryColumns={1}
    />
  );
};

export default ManualInsuranceSale;
